.full-page {
  height: 100vh;
}
.userProfile {
    margin: 8px auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .userProfile-container {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  
  .userProfile-title {
    color: grey;
    margin: 20px 0;
    text-align: center;
  }
  
  .userProfile-button {
    width: 175px;
    justify-content: center;
    border: none;
    outline: 0;
    display: inline-block;
    /* padding: 8px 16px; */
    color: white;
    background-color: var(--nav-background) !important;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    margin: auto;
    border: none !important;
    margin-bottom: 5px; 
  }
  
/* .profile-image {
  border-style: solid;
  border-width: 5px !important;
  border: var(--photo-border-color) !important;
  border-radius: 10px;
}  */
  
  /* Style the tab */
/* .tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
} */

/* Style the buttons that are used to open the tab content */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.harmony-qr {
  display: inline-flex;
  background-color: white;
  border: solid;
  border-radius: 10px;
  width: 200px;
  height: 245px;
  margin-right: 20px;
}

.gravatar-qr {
  display: inline-flex;
  background-color: white;
  border: solid;
  border-radius: 10px;
  width: 200px;
  height: 245px;
  margin-left: 20px;
}

.qr-container {
  display: block;
  padding: 10px;
  margin-top: 20px;
}

.gravatar-link {
  text-decoration: none;
  color: black;
}

.inner-container p {
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 800px) {
  .qr-container {
    display: none;
  }
  .full-page {
    overflow: hidden;
  }
}