@keyframes transition {
  from {opacity: 0;}
  to {opacity: 1;}
}

/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.profile-page-layout {
  display: block;
  width: 100%;
  height: 100vh;
  animation-name: transition;
  animation-duration: .5s;
  background-color: var(--body-background);
  overflow-y: auto;
}

.page-layout {
  display: block;
  width: 100%;
  height: 100vh;
  animation-name: transition;
  animation-duration: .5s;
  background-color: var(--body-background);
}

.profile-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.profile-picture {
  width: 150px;
  height: 150px;
  margin: 0 auto 20px;
  overflow: hidden;
  /* width: 100%;
  height: 100%;
  object-fit: cover; */
}

 .signoutButton{
  display: none;
 }

 .user-profile-container{
  height: 100vh !important;
 }