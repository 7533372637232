@keyframes transition {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

li .active {
    background-color: var(--nav-select-hover);
    color: var(--text-color);
}

.nav-logo {
    height: 230px;
}

@media (min-width: 799px){
    .mobile-name {
        display: none;
    }
    nav {
        display: block;
        width: 350px !important;
        height: 100vh;
    }

    .logo-format {
        width: 180px;
        padding-top: 30px;
    }

    .signout-button {
        color: var(--text-color);
        margin-top: 34px;
    }
    
    .signout-button:hover {
        background-color: var(--nav-select-hover);
        color: var(--text-color);
    }
}

nav {
    background-color: var(--nav-background);
    margin: 0;
    padding: 0;
}

nav ul {
    display: block;
    margin: 0;
    padding: 0;
}

li {
    margin: 0;
    list-style: none;
}

li a {
    display: block;
    text-decoration: none;
    color: var(--text-color);
    padding: 5px 0;
}

li div {
    display: block;
    text-decoration: none;
    color: var(--text-color);
    padding: 5px;
}

li a:not(.active):hover {
    background-color: var(--nav-select-hover);
}

nav .menu {
    display: none;
    position: absolute;
    top: 9px;
    right: 8px;
    flex-direction: column;
    justify-content: space-between;
    width: 36px;
    height: 32px;
}

nav .menu span {
    height: 3px;
    width: 100%;
    background-color: var(--text-color);
    border-radius: 3px;
}

@media (max-width: 799px) {
    nav .menu {
        display: flex;
        padding: 5px;
    }

    nav .menu:hover {
        border-radius: 5px;
        padding: 5px;
        background-color: var(--nav-select-hover);
    }

    nav {
        flex-direction: column;
        align-items: start;
    }

    nav ul {
        animation-name: transition;
        animation-duration: 1s;
        display: none;
        flex-direction: column;
        width: 100%;
    }

    nav ul.open {
        display: block;
        animation-name: transition;
        animation-duration: 1s;
    }

    .logo-format {
        display: inline;
        position: absolute;
        top: 7px;
        left: 10px;
        width: 35px;
    }

    .mobile-name {
        font-size: 32px;
        display: inline;
        position: absolute;
        left: 55px;
        padding: 0;
        margin: 0;
        color: var(--text-color)
    }

    .nav-logo {
        height: 50px;
    }

    .signout-button {
        color: var(--text-color);
    }
    
    .signout-button:hover {
        background-color: var(--nav-select-hover) !important;
    }
}

.nav-modal-css {
    background-color: var(--nav-background);
}

.mobile-name {
    position: absolute;
    top: 0px;
    left: 60px;
}

.mobile-nav-div {
    position: absolute;
    top: 1px;
    left: 0;
    width: 100vw;
    padding-top: 45px;
    margin: 0;
}

.mobile-nav-div a {
    text-decoration: none;
    color: white;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}

.mobile-nav-div a.active {
    background-color: var(--nav-select-hover);
}

.mobile-nav-div a:hover {
    background-color: var(--nav-select-hover);
}

#nav-one {
    margin-top: 2px;
}

.nav-modal-css {
    padding: 0;
    margin: 0;
}

.hamburger-button {
    color: white;
    font-size: 32px;
    position: absolute;
    top: 7px;
    right: 7px;
    z-index: 99;
}

@media screen and (min-width: 800px) {
    .hamburger-button {
        display: none !important;
    }
    .footer-info {
        color: white;
        font-size: 10px;
    }
    
    .footer-contact {
        text-decoration: none;
        color: white;
    }
    
    .footer-div {
        position: absolute;
        bottom: 10px;
        width: 300px;
        text-align: center;
    }
}

@media screen and (max-width: 800px) {
    .footer-info{
        display: none;
    }

    .modal-footer-info {
        color: white;
        font-size: 10px;
    }
    
    .modal-footer-contact {
        text-decoration: none;
        color: white;
    }
    
    .modal-footer-div {
        position: absolute;
        right: .1%;
        bottom: 10px;
        text-align: center;
        width: 100%;
    }
}

.footer-link {
    cursor: pointer;
}

.tab-line {
    padding-left: 40px;
}

.header {
    padding-top: 20px;
    margin-bottom: 3px;
}