  .error-page-layout {
    width: 100%;
    height: 100vh;
    overflow: auto;
    animation-name: transition;
    animation-duration: 1s;
    background-color: var(--body-background);
    padding-top: 100px;
    padding-left: 10px;
    padding-right: 10px;
  }