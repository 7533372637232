@media screen and (min-width: 0px) and (max-width: 549px) {
  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin: 8px;
    width: auto;
    height: fit-content !important;
  }

  .container {
    padding: 0 16px;
    display: auto;
  }

  .title {
    color: var(--member-title-color);
    margin: auto;
    height: auto;
  }

  .profile-image {
    margin: auto;
    align-content: center;
    height: auto;
    margin-top: 20px;
    border-style: solid;
    border: var(--photo-border-color);
    border-radius: 10px;
  }
}

@media screen and (min-width: 550px) and (max-width: 750px) {
  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin: 8px;
    width: 45%;
    height: fit-content !important;
  }

  .container {
    padding: 0 16px;
    display: auto;
  }

  .container::after,
  .row::after {
    content: "";
    clear: both;
    display: contents;
    margin: auto;
  }

  .title {
    color: var(--member-title-color);
    margin: auto;
    height: auto;
  }

  .profile-image {
    margin: auto;
    align-content: center;
    height: auto;
    margin-top: 20px;
    border-style: solid;
    border: var(--photo-border-color);
    border-radius: 10px;
  }
}

@media screen and (min-width: 751px) {
  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin: 8px;
    width: 30%;
    height: fit-content !important;
  }

.first-two-members {
  display: flex;
}

  .container {
    padding: 0 16px;
    display: auto;
  }

  .container::after,
  .row::after {
    content: "";
    clear: both;
    display: contents;
    margin: auto;
  }

  .title {
    color: var(--member-title-color);
    margin: auto;
    height: auto;
  }

  .profile-image {
    height: auto;
    margin: auto;
    margin-top: 10px;
    border-style: solid;
    border: var(--photo-border-color);
    border-radius: 10px;
  }
}
