@media screen and (min-width: 0px) and (max-width: 475px) {
  .prayer-requests {
    position: relative;
    height: 320px;
    background-color: white;
    border: solid;
    border-radius: 12px;
    border-color: transparent;
    padding-left: 2rem;
    margin: 1rem;
  }
  /* flex: 1 0 21%; explanation below */

  .prayer-descr {
    text-align: left;
    margin-right: 20px;
  }
  /* max characters 164 */

  .prayer-title {
    margin-top: 15px;
    font-weight: bold;
    text-align: left;
  }
  /* max characters 26 */

  .date {
    text-align: left;
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
  }

  .prayerequestbox {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 10px;
  }

  .control-buttons {
    position: absolute !important;
    bottom: 0px;
    margin-bottom: 10px;
  }

  .button {
    border: none !important;
    margin-right: 5px;
  }
}

@media screen and (min-width: 476px) and (max-width: 900px) {
  .prayer-requests {
    position: relative;
    height: 320px;
    background-color: white;
    border: solid;
    border-radius: 12px;
    border-color: transparent;
    padding-left: 2rem;
    margin: 1rem;
  }
  /* flex: 1 0 21%; explanation below */

  .prayer-descr {
    text-align: left;
    margin-right: 20px;
  }
  /* max characters 164 */

  .prayer-title {
    margin-top: 15px;
    font-weight: bold;
    text-align: left;
  }
  /* max characters 26 */

  .date {
    text-align: left;
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
  }

  .prayerequestbox {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 10px;
  }

  .control-buttons {
    position: absolute !important;
    bottom: 0px;
    margin-bottom: 10px;
  }

  .button {
    border: none !important;
    margin-right: 5px;
  }
}

@media screen and (min-width: 901px) and (max-width: 1200px) {
  .prayer-requests {
    position: relative;
    height: 320px;
    background-color: white;
    border: solid;
    border-radius: 12px;
    border-color: transparent;
    padding-left: 2rem;
    margin: 1rem;
  }
  /* flex: 1 0 21%; explanation below */

  .prayer-descr {
    display: flex;
    text-align: left;
    margin-right: 20px;
  }
  /* max characters 164 */

  .prayer-title {
    margin-top: 15px;
    font-weight: bold;
    text-align: left;
  }
  /* max characters 26 */

  .date {
    text-align: left;
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
  }

  .prayerequestbox {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 10px;
  }

  .control-buttons {
    position: absolute !important;
    bottom: 0px;
    margin-bottom: 10px;
  }

  .button {
    border: none !important;
    margin-right: 5px;
  }
}

@media screen and (min-width: 1201px) {
  .prayer-requests {
    position: relative;
    height: 320px;
    background-color: white;
    border: solid;
    border-radius: 12px;
    border-color: transparent;
    padding-left: 2rem;
    margin: 1rem;
    z-index: 1;
  }
  /* flex: 1 0 21%; explanation below */

  .prayer-descr {
    text-align: left;
    margin-right: 20px;
  }
  /* max characters 164 */

  .prayer-title {
    margin-top: 15px;
    font-weight: bold;
    text-align: left;
  }
  /* max characters 26 */

  .date {
    text-align: left;
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
    display: flex;
  }

  .prayerequestbox {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 10px;
  }

  .control-buttons {
    position: absolute !important;
    bottom: 0px;
    margin-bottom: 10px;
    margin: auto;
  }

  .button {
    border: none !important;
    margin-right: 5px;
  }
  /* bad css neg margin */
  .three-buttons {
    background-color: transparent !important;
    border: none !important;
    position: absolute;
    top: 0px;
    right: 0px;
    margin-top: 0px;
  }
}

.prayer-requests-list-view {
  display: flex;
  width: 96%;
  position: relative;
  background-color: white;
  border: solid;
  border-radius: 8px;
  border-color: transparent;
  padding-left: 2rem;
  margin: 1rem;
  height: 40px;
  text-align: left;
  padding-left: 0px;
  align-items: center;
}

.col {
  padding: 0px !important;
}

p {
  margin-top: 4px !important;
}

.prayerequestbox-list {
  margin-left: 80px;
  margin-top: 20px;
}

svg {
  margin: 0px !important;
}

.form-check-input:checked {
  background-color: var(--nav-select-hover) !important;
  border-color: var(--border-color) !important;
}

.form-check-input {
  border-color: var(--border-color) !important;
}

.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem var(--checkbox-shadow-color) !important;
}

.checkbox {
  position: "absolute";
  top: "0px";
  right: "0px";
  margin-right: "30px";
  font-size: '22px';
}

input.form-check-input {
  margin-right: 10px;
}

.md-circle {
  font-size: 16px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 18px;
  margin-bottom: 20px;
}

.view-more {
  color: blue;
  text-decoration: underline;
  text-align: left;
  font-size: 13px;
  border: none;
  background-color: transparent;
  float: left;
  z-index: 99;
}

.view-more:hover {
  color: rgb(0, 0, 0);
  text-decoration: underline;
}