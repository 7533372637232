:root {
  --nav-background: #0C2323;              /* Navigation bar background */
  --nav-select-hover: #123434;            /* Selected and hovered nav item */
  --body-background: #87a19f;             /* Primary body color */
  --title-bar-color: #123434;             /* Title bar on request page */
  --title-hover-color: #194848;           /* Hovered buttons on title bar request page */
  --info-tab-color: white;                /* Footer on about us */
  --text-color: white;                    /* Primary text color */
  --text-color-invert: black;             /* Inverted text color */
  --team-section-background: #e9eef1;
  --card-background: white;               /* Prayer and memeber card background */
  --member-title-color: grey;             /* Job titles of team memebers on memeber card */
  --border-color: black;                  /* Photo borders on memeber card */
  --box-shadow-color: rgba(0, 0, 0, 0.2); /* Card border shadow on member card */
  --user-title-color: grey; 
  --checkbox-shadow-color: #12343454;     /* Shadow of checkbox for prayer requests */
}

/* To be used to implement dark-light mode at a later time should we chose to */
/* [data-theme="dark-mode"] {

} */

* {
  transition: all 0.3s ease-in-out;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  overflow: hidden;
}

Button {
  box-shadow: none !important;
}
