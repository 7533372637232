@keyframes transition {
    from {opacity: 0;}
    to {opacity: 1;}
}

.page-layout {
    display: flex;
    height: 100%;
    width: 100%;
    animation-name: transition;
    animation-duration: 1s;
    overflow: hidden;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}