.sign-out-button {
    width: 175px;
    justify-content: center;
    border: none;
    outline: 0;
    display: inline-block;
    /* padding: 8px 16px; */
    color: white;
    background-color: var(--nav-background) !important;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    margin: auto;
    border: none !important;
    margin-bottom: 5px; 
}