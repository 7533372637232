@keyframes transition {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (min-width: 0px) and (max-width: 549px) {
  .about-page-layout {
    width: 100vw;
    height: 100vh;
    overflow: auto;
    animation-name: transition;
    animation-duration: 1s;
    background-color: var(--body-background);
    padding-bottom: 30px;
  }

  .about-section {
    display: block;
    background-color: var(--title-bar-color);
    color: var(--text-color);
    height: 20%;
    text-align: center;
    justify-content: center;
    height: auto;
    padding-bottom: 15px;
    padding-top: 20px;
  }

  .team-row {
    padding-top: 30px;
    display: block;
    width: 100%;
  }

  .first-two-members {
    display: grid;
    width: 100%;
    justify-content: center;
    padding-bottom: 20px;
  }

  .last-three-members {
    display: grid;
    width: 100%;
    justify-content: center;
    padding-bottom: 20px;
  } 
}

@media screen and (min-width: 550px) and (max-width: 751px) {
  .about-page-layout {
    width: 100%;
    height: 100vh;
    overflow: auto;
    animation-name: transition;
    animation-duration: 1s;
    background-color: var(--body-background);
    padding-bottom: 30px;
  }

  .about-section {
    display: block;
    background-color: var(--title-bar-color);
    color: var(--text-color);
    height: 20%;
    text-align: center;
    justify-content: center;
    height: auto;
    padding-bottom: 15px;
    padding-top: 5px;
  }

  .team-row {
    padding-top: 30px;
    display: block;
    width: 100%;
  }

  .first-two-members {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-bottom: 20px;
  }

  .last-three-members {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-bottom: 20px;
  }

}

@media screen and (min-width: 751px) {
  .about-page-layout {
    width: 100%;
    height: 100vh;
    overflow: auto;
    animation-name: transition;
    animation-duration: 1s;
    background-color: var(--body-background);
  }

  .about-section {
    display: block;
    background-color: var(--title-bar-color);
    color: var(--text-color);
    height: 20%;
    text-align: center;
    justify-content: center;
    height: auto;
    padding-bottom: 15px;
    padding-top: 5px;
  }

  .team-row {
    padding-top: 30px;
    display: block;
    width: 100%;
  }

  .first-two-members {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-bottom: 20px;
  }

  .last-three-members {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-bottom: 20px;
  }
}

.About-Us {
  text-align: center;
  width: 100%;
  font-size: 40px;
  font-weight:600;
}