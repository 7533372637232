.prayer-list-box {
  background-color: var(--body-background);
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

@media screen and (max-width: 800px) {
  .prayer-list-box {
      padding-bottom: 60px;
  }
}