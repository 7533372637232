.search-bar {
  width: 100%;
  background-color: white;
  height: 65px;
  resize: vertical;
  box-sizing: border-box;
}

/* .create-request-button {
    float: right;
    margin-right: 40px;
    margin-top: 15px;
} */

.signup-form {
  margin-left: 20px;
  margin-right: 20px;
}
