/* Define styles for the title bar */
.title-bar {
  height: 75px; /* Set the height of the title bar */
  display: flex; /* Display the title bar as a flex container */
  margin: auto; /* Center the title bar horizontally */
  text-align: left; /* Align the text to the left */
  background-color: var(--title-bar-color); /* Set the background color using a CSS variable */
  z-index: 99; /* Set the z-index to ensure the title bar stays on top */
  position: sticky; /* Make the title bar sticky so it stays at the top of the page */
  top: 0; /* Position the title bar at the top of the page */
}

/* Style the button */
.button {
  background-color: transparent !important; /* Set the background color to transparent */
  height: 40px !important; /* Set the height of the button */
}

/* Style the button on hover */
.button:hover {
  background-color: var(--title-hover-color) !important; /* Set the background color on hover using a CSS variable */
}

/* Hide the dropdown toggle arrow */
.dropdown-toggle::after {
  display: none !important;
}

/* Media query for screens with a maximum width of 800px */
@media screen and (min-width: 0px) and (max-width: 800px) {
  /* Adjust the height of the title bar */
  .title-bar {
    height: 110px;
  }

  /* Style the title text */
  h1 {
    font-size: 40px; /* Set the font size */
    text-align: left; /* Align the text to the left */
    margin-left: 25px; /* Set the left margin */
    padding-top: 10px !important; /* Set the top padding */
    /* width: 100%; Set the width to 100% */
    color: var(--text-color) !important; /* Set the text color using a CSS variable */
  }

  /* Display the title bar as a block element */
  .title-bar {
    display: block !important;
  }

  /* Style the button container */
  .button-div {
    display: flex; /* Display the container as a flex container */
    width: 200px; /* Set the width of the container */
    padding-left: 10px; /* Set the left padding */
    padding-bottom: 10px; /* Set the bottom padding */
  }
}

/* Media query for screens with a minimum width of 801px */
@media screen and (min-width: 801px) {
  /* Style the filter controls */
  .filter-controls {
    display: flex; /* Display the filter controls as a flex container */
    float: right; /* Float the filter controls to the right */
    margin-bottom: 50px; /* Set the bottom margin */
  }

  /* Style the button container */
  .button-div {
    display: flex; /* Display the container as a flex container */
    float: right; /* Float the container to the right */
    margin-right: 30px; /* Set the right margin */
    margin-bottom: 25px; /* Set the bottom margin */
    margin-top: 10px; /* Set the top margin */
  }

  /* Style the title text */
  h1 {
    font-size: 31px !important; /* Set the font size */
    text-align: left; /* Align the text to the left */
    margin-left: 25px; /* Set the left margin */
    margin-top: 17px !important; /* Set the top margin */
    width: 100%; /* Set the width to 100% */
    color: var(--text-color) !important; /* Set the text color using a CSS variable */
  }

  /* Adjust the button margin */
  Button {
    margin-top: 5px !important;
  }
}

/* Media query for screens with a maximum width of 1202px */
@media screen and (max-width: 1202px) {
  /* Hide the list button */
  #list-button {
    display: none;
  }
}
  
.data-input {
  padding-top: 10px !important;
}
