.group-list-page {
    height: 100%;
    background-color: var(--body-background);
}

.group-item {
    background-color: white;
    width: 90%;
    margin-left: 5%;
    margin-top: 0px !important;
    padding: 10px;
    border-radius: 10px;
    font-size: 30px;
    font-weight: 500;
}

.group-item:hover {
    color: white;
    background-color: var(--nav-select-hover);
}

@media screen and (max-width: 800px) {
    .group-list-page {
        padding-bottom: 45px;
    }
}