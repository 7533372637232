.group-title-bar {
    height: 75px;
    display: flex;
    margin: auto;
    text-align: left;
    background-color: var(--title-bar-color);
    z-index: 99;
    position: sticky;
    top: 0; 
    padding-top: 8px;
  }
  
 .group-div {
    margin-left: auto !important;
 }

  .group-button {
    background-color: transparent !important;
    height: 40px !important;
    border: none !important;
    margin-left: auto !important;
    margin-right: 25px !important;
    margin-top: 8px !important;
  }
  
  .group-button:hover {
    background-color: var(--title-hover-color) !important;
  }